import React, { useState } from 'react';
import { Receipt, Search } from '../assets';
import Button from '../components/Button';
import OrWithLines from '../components/OrWithLines';
import { STATES } from '../constants';
import { useNavigate } from 'react-router-dom';

const SearchTicket = () => {
 const [citationNumber, setCitationNumber] = useState('');
 const [licensePlate, setLicensePlate] = useState('');
 const [state, setState] = useState('');
 const [error, setError] = useState('');

 const navigate = useNavigate();

 // Handle input change for citation number
 const handleCitationChange = (e) => {
  const { value } = e.target;
  setCitationNumber(value);

  // Clear error on change if the input is valid
  if (error && (!value || !/^\d*$/.test(value))) {
   setError('');
  }
 };

 // Handle input change for license plate
 const handleLicensePlateChange = (e) => {
  const { value } = e.target;
  setLicensePlate(value);
 };

 // Handle state change
 const handleStateChange = (e) => {
  const { value } = e.target;
  setState(value);
 };

 // Validate input on blur for citation number
 const handleCitationBlur = () => {
  if (!/^\d*$/.test(citationNumber)) {
   setError('Please enter a valid number');
  } else {
   setError('');
  }
 };

 return (
  <div className="flex flex-col justify-center items-center mx-auto h-screen  ">
   <div className="max-w-[426px]">
    <div className="flex justify-between items-center gap-6 pb-[64px]">
     <Receipt />
     <p className=" text-[24px] md:text-[34px] font-semibold  tracking-[3.45px] md:tracking-[5px]">
      Ticket Online Pay
     </p>
    </div>
    {/* Search by Citation Number */}
    <div className="flex flex-col md:flex-row items-start mb-4">
     <div className="flex-grow w-full pb-2 md:pb-0 ">
      <input
       type="text"
       name="citationNumber"
       value={citationNumber}
       onChange={handleCitationChange}
       onBlur={handleCitationBlur}
       placeholder="Citation #"
       className={`font-normal bg-white placeholder:font-normal placeholder:text-sm border w-full ${
        error ? 'border-red-500' : 'border-neutral03'
       } hover:border-black hover:border-opacity-30 focus:border-black focus:border-opacity-30 caret-primary05 outline-none rounded-md py-2 pr-10 focus:outline-none`}
      />
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
     </div>
     <Button
      customClass=" w-full md:w-auto  md:ml-2  "
      name={'Search'}
      onClick={() => {
       !error && navigate('/ticket');
      }}
      size="medium"
      variant="primary"
      svg={<Search />}
      svgBefore={true}
     />
    </div>
    <OrWithLines />
    {/* Search by License Plate */}
    <div className="flex flex-col md:flex-row justify-between items-center gap-2">
     <div className="flex flex-grow gap-2">
      <input
       type="text"
       name="licensePlate"
       value={licensePlate}
       onChange={handleLicensePlateChange}
       placeholder="License Plate"
       className="font-normal bg-white placeholder:font-normal placeholder:text-sm border border-neutral03 hover:border-black hover:border-opacity-30 focus:border-black focus:border-opacity-30 caret-primary05 outline-none rounded-md py-2 pr-10 focus:outline-none flex-1 w-full"
      />
      <div className="relative w-[95px]">
       <select
        name="state"
        value={state}
        onChange={handleStateChange}
        className="font-normal bg-white placeholder:font-normal placeholder:text-sm border border-neutral03 hover:border-black hover:border-opacity-30 focus:border-black focus:border-opacity-30 caret-primary05 outline-none rounded-md py-2 pr-10 focus:outline-none w-full"
       >
        <option value="">State</option>
        {STATES.map(({ value, label }) => (
         <option key={value} value={value}>
          {label}
         </option>
        ))}
       </select>
      </div>
     </div>

     {/* Adjust the margin here for the gap */}
     <Button
      customClass=" w-full md:w-auto" // Ensure the button has auto width to accommodate its content
      name="Search"
      onClick={() => {}}
      size="medium"
      variant="primary"
      //   fullWidth={true}
      svg={<Search style={{ fill: 'black' }} />}
      svgBefore={true}
     />
    </div>
   </div>
  </div>
 );
};

export default SearchTicket;
