import React, { useState } from 'react';
import { Card, Image } from '../assets';
import Button from './Button';
import { useNavigate } from 'react-router-dom';

const Accordion = ({ data, content }) => {
 const [isOpen, setIsOpen] = useState(false);
 const navigate = useNavigate();

 return (
  <div className="pb-[10px]">
   <div
    className={`flex flex-col gap-3 md:gap-0 md:flex-row md:flex-wrap justify-between md:items-center px-4 py-2 cursor-pointer ${
     isOpen ? ' bg-black bg-opacity-5' : 'bg-white bg-bg-opacity-5'
    } hover:bg-black hover:bg-opacity-5`}
    onClick={() => setIsOpen(!isOpen)}
   >
    <div className="flex justify-between md:hidden items-baseline">
     <p className="text-sm font-bold text-black text-opacity-50">Date: </p>
     <p className="w-[130.63px] break-words text-sm text-black ">{data.date}</p>
    </div>
    <p className="w-[130.63px] break-words hidden md:block text-sm text-black ">
     {data.date}
    </p>
    <div className="flex justify-between items-baseline md:hidden">
     <p className="text-sm font-bold text-black text-opacity-50">Vehicle: </p>
     <p className="w-[130.63px] break-words block md:hidden text-sm text-black">
      {data.vehicle}
     </p>
    </div>
    <p className="w-[167px] break-words hidden md:block">{data.vehicle}</p>
    <div className="flex justify-between items-baseline md:hidden">
     <p className="text-sm font-bold text-black text-opacity-50">Violation: </p>
     <p className="w-[130.63px] break-words text-sm text-black">{data.issue}</p>
    </div>
    <p className="w-[124px] break-words hidden md:block text-sm text-black">
     {data.issue}
    </p>
    <div className="flex justify-between items-baseline md:hidden">
     <p className="text-sm font-bold text-black text-opacity-50">Status: </p>
     <p className="w-[130.63px] break-words text-sm text-black">
      {data.paymentStatus}
     </p>
    </div>
    <p className="w-[90px] break-words hidden md:block text-sm text-black">
     {data.paymentStatus}
    </p>
    <div className="flex justify-between items-baseline md:hidden">
     <p className="text-sm font-bold text-black text-opacity-50">Owed: </p>
     <p className="w-[130.63px] break-words text-sm text-black">
      {data.amount}
     </p>
    </div>
    <p className="w-[48px] break-words hidden md:block text-sm text-black">
     {data.amount}
    </p>
   </div>
   {isOpen && (
    <div className="bg-gray09 flex pt-[10px]">
     <div className="flex flex-col gap-2">
      <div className="flex flex-col md:flex-row md:flex-1 justify-between items-stretch gap-2 px-2">
       <div className="flex flex-1 flex-col bg-black bg-opacity-5 py-2 gap-[10px] px-[22px] md:pl-[22px]">
        <p className="text-xs font-semibold">Address of Violation:</p>
        <p className="text-xs text-black text-opacity-70">
         04914 Murray Lights Apt. 037, Fort Collins, 21085
        </p>
       </div>
       <div className="flex flex-1 flex-col bg-black bg-opacity-5 py-2 gap-[10px] pl-[22px]">
        <p className="text-xs font-semibold">Description:</p>
        <p className="text-xs text-black text-opacity-70">
         A description added for the violation
        </p>
       </div>
      </div>
      <div className="flex flex-col md:flex-row md:flex-1 justify-between items-stretch gap-2 px-2">
       <div className="flex flex-1 flex-col bg-black bg-opacity-5 py-2 gap-[10px] px-[22px] md:pl-[22px]">
        <p className="text-xs font-semibold">Citation #:</p>
        <p className="text-xs text-black text-opacity-70">61417461416</p>
       </div>
       <div className="flex flex-1 flex-col bg-black bg-opacity-5 py-2 gap-[10px] pl-[22px]">
        <p className="text-xs font-semibold">License Plate:</p>
        <p className="text-xs text-black text-opacity-70">MG-7105J MD</p>
       </div>
       <div className="flex flex-1 flex-col bg-black bg-opacity-5 py-2 gap-[10px] pl-[22px]">
        <p className="text-xs font-semibold">Evidence:</p>
        <div className="flex justify-start items-center gap-2">
         <Image />
         <p className="text-xs text-black text-opacity-70">1242512421.png</p>
        </div>
       </div>
      </div>
      <div className="flex flex-col px-2 md:px-0 md:flex-row  md:justify-end items-center gap-2 pb-[6px] text-nowrap  ">
       <Button
        name={'Contest Citation'}
        customClass=" !tracking-[2px] w-full md:w-auto"
        onClick={() => navigate('/contest-citation')}
        size="small"
        variant="secondary"
       />
       <Button
        name={'Pay My Permit'}
        customClass=" !tracking-[2px] md:mr-2 w-full md:w-auto"
        onClick={() => navigate('/pay-citation')}
        size="small"
        variant="primary"
        svg={<Card />}
        svgBefore={true}
       />
      </div>
     </div>
    </div>
   )}
  </div>
 );
};

export default Accordion;
