import React from 'react';

const DetailsComponent = ({
 titleLeft,
 descriptionLeft,
 titleRight,
 descriptionRight,
}) => {
 return (
  <div className="flex flex-col md:flex-row justify-between gap-2 w-full">
   <div className="flex-1  ">
    <div className="flex flex-col justify-start items-start gap-3">
     <p className="text-xs font-semibold">{titleLeft} </p>
     <p className="text-black text-opacity-70">{descriptionLeft}</p>
    </div>
   </div>
   <div className="flex-1  ">
    <div className="flex flex-col justify-start items-start gap-3">
     <p className="text-xs font-semibold">{titleRight}</p>
     <p className="text-black text-opacity-70">{descriptionRight}</p>
    </div>
   </div>
  </div>
 );
};

export default DetailsComponent;
