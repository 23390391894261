import React from 'react';
import VehicleDetailCard from '../components/VehicleDetailCard';
import LogoWithAddress from '../components/LogoWithAddress';
import { Back, Car, Citation } from '../assets';
import Accordion from '../components/Accordion';
import { data } from '../constants';
import { useNavigate } from 'react-router-dom';

const TicketDetails = () => {
 const navigate = useNavigate();
 return (
  <>
   <div className="flex flex-col justify-center items-center">
    <LogoWithAddress />
    <div className=" md:max-w-[704px] pb-11  ">
     <div className="flex  justify-start items-center gap-2 pb-[18px]">
      <Car />
      <p className="text-lg font-medium">Vehicles</p>
     </div>
     <div className="flex  flex-col items-center md:flex-row md:items-baseline md:flex-wrap justify-between gap-4 pb-[32px]">
      {[1, 2, 3, 4].map((data, idx) => {
       return <VehicleDetailCard isPaid={idx !== 2} />;
      })}
     </div>
     <div className="border-b"></div>
     <div className="flex  justify-start items-center gap-2 pb-[18px] pt-[32px]">
      <Citation />
      <p className="text-lg font-medium">Citations</p>
     </div>
     <div className="border">
      <div className=" hidden md:flex justify-between items-center bg-black bg-opacity-5 px-4 py-2 text-wrap">
       <p className="w-[130.63px] text-sm font-bold text-black text-opacity-50">
        Date/Time
       </p>
       <p className="w-[167px] text-sm font-bold text-black text-opacity-50">
        Vehicle
       </p>
       <p className="w-[124px] text-sm font-bold text-black text-opacity-50">
        Violation
       </p>
       <p className="w-[90px] text-sm font-bold text-black text-opacity-50">
        Status
       </p>
       <p className="w-[48px] text-sm font-bold text-black text-opacity-50">
        Owed
       </p>
      </div>
      {data.map((item) => (
       <Accordion
        key={item.id}
        data={{
         date: item.date,
         vehicle: item.vehicle,
         issue: item.issue,
         paymentStatus: item.paymentStatus,
         amount: item.amount,
        }}
        content={'item.details'}
       />
      ))}
     </div>
    </div>
   </div>
   <div className="absolute top-[37px] left-[30px] cursor-pointer">
    <div
     className="flex justify-start items-center gap-[10px]"
     onClick={() => navigate(-1)}
    >
     <Back />
     <p>Back</p>
    </div>
   </div>{' '}
  </>
 );
};

export default TicketDetails;
