import React from 'react';
import { Car, Card } from '../assets';
import Button from './Button';
import { useNavigate } from 'react-router-dom';

const VehicleDetailCard = (props) => {
 const navigate = useNavigate();
 const { isPaid } = props;
 return (
  <div className="flex-1 max-w-[323px] md:max-w-[230px] min-w-[222px] bg-black bg-opacity-5 p-4 h-full ">
   <div className="">
    <div className="flex  items-center gap-2 ">
     <Car />
     <p className="text-xs font-semibold tracking-[2px] ">Blk Subaru Impreza</p>
    </div>
    <div className="flex flex-col gap-2 py-4">
     <div className="flex  justify-between gap-2 ">
      <span className="text-xs text-black text-opacity-50 min-w-[110px]">
       License Plate:
      </span>
      <span className="flex-1 text-xs text-black text-left">12LLO</span>
     </div>
     <div className="flex flex-wrap  justify-between gap-2 ">
      <span className="text-xs  text-black text-opacity-50  min-w-[110px]">
       Status:
      </span>
      <span className="flex-1 text-xs text-black text-left ">Active</span>
     </div>
     <div className="flex  justify-between gap-2 ">
      <span className="text-xs text-black text-opacity-50 min-w-[110px]">
       Reg. Expiring:
      </span>
      <span className="flex-1 text-xs text-black text-left">09/21/2024</span>
     </div>
     <div className="flex flex-col justify-between gap-2 ">
      <span className="text-xs flex-1 text-black text-opacity-50 min-w-[110px]">
       Registration Address:
      </span>
      <span className="flex-1 text-xs text-black text-left">
       04914 Murray Lights Apt. 037, Pleasantville, NC, 21085
      </span>
     </div>
    </div>
   </div>

   <div className="border-b"></div>

   <div className="pb-4">
    <div className="flex  items-start  gap-2 ">
     <p className="text-xs font-bold tracking-[2px] ">Parking permit:</p>
    </div>
    <div className="flex flex-col gap-2 pt-2">
     <div className="flex flex-wrap  justify-between gap-2 ">
      <span className="text-xs  text-black text-opacity-50  min-w-[62px]">
       Status:
      </span>
      <span className="flex-1 text-xs text-black text-left min-w-[62px] ">
       Active
      </span>
     </div>
     <div className="flex flex-wrap  justify-between gap-2 ">
      <span className="text-xs  text-black text-opacity-50  min-w-[62px]">
       Issued:
      </span>
      <span className="flex-1 text-xs text-black text-left min-w-[62px] ">
       09/21/2024
      </span>
     </div>
     <div className="flex flex-wrap  justify-between gap-2 ">
      <span className="text-xs  text-black text-opacity-50  min-w-[62px]">
       Expires:
      </span>
      <span className="flex-1 text-xs text-black text-left min-w-[62px] ">
       09/21/2024
      </span>
     </div>
    </div>
   </div>
   {!isPaid && (
    <Button
     name={'Pay My Permit'}
     onClick={() => navigate('/pay-citation')}
     size="small"
     fullWidth={true}
     variant="primary"
     svg={<Card />}
     svgBefore={true}
    />
   )}
  </div>
 );
};

export default VehicleDetailCard;
