import './App.css';
import { Logo } from './assets';
import Button from './components/Button';
import Router from './components/Router';

function App() {
 return <Router />;
}

export default App;
