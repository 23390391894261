export const STATES = [
 { key: 'Alabama', label: 'Alabama', value: 'AL' },
 { key: 'Alaska', label: 'Alaska', value: 'AK' },
 { key: 'American Samoa', label: 'American Samoa', value: 'AS' },
 { key: 'Arizona', label: 'Arizona', value: 'AZ' },
 { key: 'Arkansas', label: 'Arkansas', value: 'AR' },
 { key: 'California', label: 'California', value: 'CA' },
 { key: 'Colorado', label: 'Colorado', value: 'CO' },
 { key: 'Connecticut', label: 'Connecticut', value: 'CT' },
 { key: 'Delaware', label: 'Delaware', value: 'DE' },
 {
  key: 'District Of Columbia',
  label: 'District Of Columbia',
  value: 'DC',
 },
 {
  key: 'Federated States Of Micronesia',
  label: 'Federated States Of Micronesia',
  value: 'FM',
 },
 { key: 'Florida', label: 'Florida', value: 'FL' },
 { key: 'Georgia', label: 'Georgia', value: 'GA' },
 { key: 'Guam Gu', label: 'Guam Gu', value: 'GU' },
 { key: 'Hawaii', label: 'Hawaii', value: 'HI' },
 { key: 'Idaho', label: 'Idaho', value: 'ID' },
 { key: 'Illinois', label: 'Illinois', value: 'IL' },
 { key: 'Indiana', label: 'Indiana', value: 'IN' },
 { key: 'Iowa', label: 'Iowa', value: 'IA' },
 { key: 'Kansas', label: 'Kansas', value: 'KS' },
 { key: 'Kentucky', label: 'Kentucky', value: 'KY' },
 { key: 'Louisiana', label: 'Louisiana', value: 'LA' },
 { key: 'Maine', label: 'Maine', value: 'ME' },
 {
  key: 'Marshall Islands',
  label: 'Marshall Islands',
  value: 'MH',
 },
 { key: 'Maryland', label: 'Maryland', value: 'MD' },
 { key: 'Massachusetts', label: 'Massachusetts', value: 'MA' },
 { key: 'Michigan', label: 'Michigan', value: 'MI' },
 { key: 'Minnesota', label: 'Minnesota', value: 'MN' },
 { key: 'Mississippi', label: 'Mississippi', value: 'MS' },
 { key: 'Missouri', label: 'Missouri', value: 'MO' },
 { key: 'Montana', label: 'Montana', value: 'MT' },
 { key: 'Nebraska', label: 'Nebraska', value: 'NE' },
 { key: 'Nevada', label: 'Nevada', value: 'NV' },
 { key: 'New Hampshire', label: 'New Hampshire', value: 'NH' },
 { key: 'New Jersey', label: 'New Jersey', value: 'NJ' },
 { key: 'New Mexico', label: 'New Mexico', value: 'NM' },
 { key: 'New York', label: 'New York', value: 'NY' },
 { key: 'North Carolina', label: 'North Carolina', value: 'NC' },
 { key: 'North Dakota', label: 'North Dakota', value: 'ND' },
 {
  key: 'Northern Mariana Islands',
  label: 'Northern Mariana Islands',
  value: 'MP',
 },
 { key: 'Ohio', label: 'Ohio', value: 'OH' },
 { key: 'Oklahoma', label: 'Oklahoma', value: 'OK' },
 { key: 'Oregon', label: 'Oregon', value: 'OR' },
 { key: 'Palau', label: 'Palau', value: 'PW' },
 { key: 'Pennsylvania', label: 'Pennsylvania', value: 'PA' },
 { key: 'Puerto Rico', label: 'Puerto Rico', value: 'PR' },
 { key: 'Rhode Island', label: 'Rhode Island', value: 'RI' },
 { key: 'South Carolina', label: 'South Carolina', value: 'SC' },
 { key: 'South Dakota', label: 'South Dakota', value: 'SD' },
 { key: 'Tennessee', label: 'Tennessee', value: 'TN' },
 { key: 'Texas', label: 'Texas', value: 'TX' },
 { key: 'Utah', label: 'Utah', value: 'UT' },
 { key: 'Vermont', label: 'Vermont', value: 'VT' },
 { key: 'Virgin Islands', label: 'Virgin Islands', value: 'VI' },
 { key: 'Virginia', label: 'Virginia', value: 'VA' },
 { key: 'Washington', label: 'Washington', value: 'WA' },
 { key: 'West Virginia', label: 'West Virginia', value: 'WV' },
 { key: 'Wisconsin', label: 'Wisconsin', value: 'WI' },
 { key: 'Wyoming', label: 'Wyoming', value: 'WY' },
];

export const data = [
 {
  id: 1,
  date: '2024:7:11 17:11:4',
  vehicle: 'White Chevrolet Equinox',
  issue: 'Double Parking',
  paymentStatus: 'Not Paid',
  amount: '$70',
  details:
   'This record pertains to a vehicle that was parked in a restricted area. The payment for the fine is pending.',
 },
 {
  id: 2,
  date: '2024:7:12 09:45:30',
  vehicle: 'Black Ford F-150',
  issue: 'Expired Registration',
  paymentStatus: 'Paid',
  amount: '$100',
  details:
   'The vehicle had an expired registration which has now been updated. The fine has been paid.',
 },
 {
  id: 3,
  date: '2024:7:13 15:20:45',
  vehicle: 'Blue Honda Civic',
  issue: 'Overdue Inspection',
  paymentStatus: 'Not Paid',
  amount: '$50',
  details:
   'The vehicle was found to be overdue for inspection. Payment for the fine is still pending.',
 },
 {
  id: 4,
  date: '2024:7:14 11:30:00',
  vehicle: 'Gray Toyota Corolla',
  issue: 'Illegal Parking',
  paymentStatus: 'Paid',
  amount: '$80',
  details:
   'The vehicle was parked in a no-parking zone. The fine has been settled and the issue resolved.',
 },
 {
  id: 5,
  date: '2024:7:15 14:05:10',
  vehicle: 'Red Tesla Model 3',
  issue: 'Broken Taillight',
  paymentStatus: 'Not Paid',
  amount: '$60',
  details:
   'The vehicle had a broken taillight which was noted during a routine check. Payment for the repair is pending.',
 },
];
