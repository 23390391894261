import React from 'react';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';

const MainLayout = () => {
 return (
  <>
   <div className=" px-[26px]  ">
    <Outlet />
   </div>
   <Footer />
  </>
 );
};

export default MainLayout;
