import React from 'react';
import { Attach, Back, Citation, Logo, Submit } from '../assets';
import DetailsComponent from '../components/DetailsComponent';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';

const ContestCitation = () => {
 const navigate = useNavigate();
 return (
  <>
   <div className="py-[30px]">
    <div className="flex justify-center items-center">
     <Logo />
    </div>
    <div className="flex justify-center items-center w-full">
     <div className=" flex-1 flex-col justify-start max-w-[373px] md:max-w-[429px] ">
      <div className="flex flex-col justify-start items-start gap-[18px]">
       <div className="flex flex-col justify-start items-start gap-3">
        <p className="text-xs font-semibold">Owner:</p>
        <p className="text-black text-opacity-70">John Hancock</p>
       </div>
       <div className="flex flex-col justify-start items-start gap-3">
        <p className="text-xs font-semibold">Owner’s Address:</p>
        <p className="text-black text-opacity-70">
         04914 Murray Lights Apt. 037, Fort Collins, NC, 21085
        </p>
       </div>
       <div className="border-b w-full"></div>
       <div className="flex flex-col justify-start items-start gap-3">
        <p className="text-xs font-semibold">Vehicle:</p>
        <p className="text-black text-opacity-70">Blk Subaru Impreza</p>
       </div>
       <DetailsComponent
        titleLeft="License Plate: "
        descriptionLeft="43BCI8"
        titleRight="State:"
        descriptionRight="NC"
       />
       <DetailsComponent
        titleLeft="Status:  "
        descriptionLeft="Active"
        titleRight="Reg. Expiring:"
        descriptionRight="09/21/2024"
       />
       <div className="flex flex-col justify-start items-start gap-3">
        <p className="text-xs font-semibold">Registration Address:</p>
        <p className="text-black text-opacity-70">
         04914 Murray Lights Apt. 037, Fort Collins, NC, 21085
        </p>
       </div>
       <div className="border-b w-full"></div>
       <DetailsComponent
        titleLeft="Date/Time: "
        descriptionLeft="12/20/2024 / 2:42PM"
        titleRight="Type of Violation:"
        descriptionRight="Parking in bike lane"
       />
       <DetailsComponent
        titleLeft="Parking in bike lane "
        descriptionLeft="Not Paid"
        titleRight="Amount Owed:"
        descriptionRight="Amount Owed:"
       />
       <DetailsComponent
        titleLeft="Citation #:"
        descriptionLeft="Not Paid"
        titleRight="Evidence:"
        descriptionRight="1242512421.png"
       />
       <div className="flex flex-col justify-start items-start gap-3">
        <p className="text-xs font-semibold">Address of Violation:</p>
        <p className="text-black text-opacity-70">
         04914 Murray Lights Apt. 037, Fort Collins, NC, 21085
        </p>
       </div>
       <div className="border-b w-full"></div>
       <div>
        <div className="flex  justify-start items-center gap-2 pb-[16px] pt-[18px]">
         <Citation />
         <p className="text-lg font-medium">Contest Citation</p>
        </div>
        <p className="text-xs text-black text-opacity-70">
         Please enter an explanation for why you’re contesting this citation.
        </p>
       </div>
       <textarea
        id="w3review"
        name="w3review"
        rows="4"
        cols="50"
        className="border border-black border-opacity-30 rounded-[5px] max-w-[373px] md:max-w-[429px]"
       />
       <div className="flex justify-between items-center w-full gap-4">
        <Button
         name={'Submit'}
         onClick={() => {}}
         size="small"
         fullWidth={true}
         variant="primary"
         svg={<Submit />}
         svgBefore={true}
         customClass=" flex-1 !tracking-[2px] w-full "
        />
        <Button
         name={'Attach File'}
         onClick={() => {}}
         size="small"
         fullWidth={true}
         variant="secondary"
         svg={<Attach />}
         svgBefore={true}
         customClass=" flex-1 !tracking-[2px] w-full"
        />
       </div>
      </div>
     </div>
    </div>
   </div>
   <div className="absolute top-[37px] left-[30px] cursor-pointer">
    <div
     className="flex justify-start items-center gap-[10px]"
     onClick={() => navigate(-1)}
    >
     <Back />
     <p>Back</p>
    </div>
   </div>
  </>
 );
};

export default ContestCitation;
