import React from 'react';
import { Logo } from '../assets';

const LogoWithAddress = () => {
 return (
  <div className="flex flex-col md:flex-row justify-center items-center gap-9   py-4">
   <Logo className="w-[156px] h-[156px]" />
   <div className="flex flex-col gap-[18px] max-w-[230px]  ">
    <div className="flex flex-col justify-start  gap-3">
     <p className="font-semibold">Owner:</p>
     <p className="text-black text-opacity-70">John Hancock</p>
    </div>
    <div className="flex flex-col justify-start  gap-3">
     <p className="font-semibold">Owner’s Address:</p>
     <p className="text-black text-opacity-70">
      04914 Murray Lights Apt. 037, Fort Collins, South Carolina, 21085
     </p>
    </div>
   </div>
  </div>
 );
};

export default LogoWithAddress;
