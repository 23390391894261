import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from './MainLayout';
import SearchTicket from '../pages/SearchTicket';
import TicketDetails from '../pages/TicketDetails';
import ContestCitation from '../pages/ContestCitation';
import PayCitation from '../pages/PayCitation';
import ContactUs from '../pages/ContactUs';

const Router = () => {
 return (
  <Routes>
   <Route path="/" element={<MainLayout />}>
    <Route index element={<SearchTicket />} />
    <Route path="search-ticket" element={<SearchTicket />} />
    <Route path="ticket" element={<TicketDetails />} />
    <Route path="contest-citation" element={<ContestCitation />} />
    <Route path="pay-citation" element={<PayCitation />} />
    <Route path="contact-us" element={<ContactUs />} />
    <Route path="*" element={<Navigate to="/" />} />
   </Route>
  </Routes>
 );
};

export default Router;
