import React from 'react';
import { Attach, Back, Logo, Submit } from '../assets';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';

const ContactUs = () => {
 const navigate = useNavigate();
 return (
  <>
   <div className="py-[30px] ">
    <div className="flex justify-center items-center pb-8">
     <Logo />
    </div>
    <div className="flex justify-center items-center w-full">
     <div className=" flex-1 flex-col justify-start max-w-[323px] md:max-w-[429px] ">
      <div className="border-b w-full"></div>
      <div className="flex flex-col justify-start items-start text-start gap-3 w-full pb-9 md:pb-[18px] pt-8 ">
       <p className="text-balance text-lg font-medium"> Contact Us</p>
       <p className="text-xs text-black text-opacity-70">
        We try to respond to everyone as quickly as possible. Expect to receive
        a reply from us within 1 business day.
       </p>
      </div>
      <textarea
       id="w3review"
       name="w3review"
       rows="4"
       cols="50"
       className="border border-black border-opacity-30 rounded-[5px] max-h-[234px] md:max-h-[101px] max-w-[323px] md:max-w-[429px]"
      />
      <div className="flex flex-col md:flex-row justify-between items-center w-full gap-4 pt-9 md:pt-0">
       <Button
        name={'Submit'}
        onClick={() => {}}
        size="small"
        fullWidth={true}
        variant="primary"
        svg={<Submit />}
        svgBefore={true}
        customClass=" flex-1 !tracking-[2px] w-full "
       />
       <Button
        name={'Attach File'}
        onClick={() => {}}
        size="small"
        fullWidth={true}
        variant="secondary"
        svg={<Attach />}
        svgBefore={true}
        customClass=" flex-1 !tracking-[2px] w-full"
       />
      </div>
     </div>
    </div>
   </div>
   <div className="absolute top-[37px] left-[30px] cursor-pointer">
    <div
     className="flex justify-start items-center gap-[10px]"
     onClick={() => navigate(-1)}
    >
     <Back />
     <p>Back</p>
    </div>
   </div>
  </>
 );
};

export default ContactUs;
