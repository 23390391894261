import React from 'react';
import clsx from 'clsx';
import { AiOutlineLoading } from 'react-icons/ai';

const Button = (props) => {
 const {
  border = 'primary',
  name,
  onClick,
  svg,
  svgBefore = false,
  variant,
  size,
  type = 'button',
  halfWidth,
  loading,
  isDisabled = loading ?? false,
  fullWidth = false,
  customClass,
 } = props;

 return (
  <button
   type={type}
   disabled={isDisabled ? true : false}
   onClick={onClick}
   className={clsx(
    `flex-shrink-0 outline-none ${
     loading ? 'flex justify-center items-center' : ''
    }${customClass} `,
    {
     'bg-black primaryButton border border-black hover:border-black hover:shadow-secondaryButtonShadow hover:border-opacity-5 text-white hover:bg-black   focus:active:bg-black focus:active:bg-opacity-85 focus:active:border-black focus:active:border-opacity-90  focus:active:text-white':
      variant === 'primary' && isDisabled === false, // button primary

     'bg-white secondaryButton border border-black   text-black hover:shadow-secondaryButtonShadow hover:border-gray08   hover:text-black focus:active:text-white focus:active:border-gray08  ':
      variant === 'secondary' && isDisabled === false, // button secondary

     'tertiaryButton border border-white text-primary600 hover:text-primary700 hover:border hover:border-neutral100 hover:hover:bg-neutral100 focus:active:border focus:active:border-neutral100 focus:active:text-primary800':
      variant === 'tertiary' && isDisabled === false, // button tertiary

     'py-[12px] px-[16px] text-base font-medium': size === 'large', // button large
     'py-[10px] px-[36px] text-sm font-medium': size === 'medium', // button medium
     'py-[8px] px-[36px]  text-xs font-bold': size === 'small', // button small
     'py-[4px] px-[8px] text-xs font-normal': size === 'extraSmall', // button extra small
     'rounded m-0': border === 'secondary', // button rounded
     'rounded-full': border === 'full',
     'rounded-[4px]': border === 'primary', // button rounded full
     ' bg-gray08 border-gray08  text-white  ':
      variant === 'primary' && isDisabled === true, // primary button disabled
     'bg-white secondaryButton border border-primary500 opacity-50 text-primary500':
      variant === 'secondary' && isDisabled === true, // secondary button disabled
     'border border-white text-primary600 opacity-50 hover:text-primary700':
      variant === 'tertiary' && isDisabled === true, // tertiary button disabled
     'w-full': fullWidth === true,
     'md:w-1/2': halfWidth === true,
    },
   )}
  >
   {loading ? (
    <AiOutlineLoading
     className={`h-5 w-5 animate-spin ${
      variant === 'primary' ? 'text-white' : 'text-primary500'
     }`}
    />
   ) : (
    <div className="flex flex-row items-center gap-2 justify-center">
     {svgBefore && svg} {/* if svg/image comes before the name */}
     {name}
     {svgBefore === false && svg} {/* if svg/image comes after the name */}
    </div>
   )}
  </button>
 );
};

export default Button;
